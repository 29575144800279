import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faTasks } from '@fortawesome/pro-light-svg-icons/faTasks';

import { faQuestionCircle as faQuestionCircleLight } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faQuestionCircle as faQuestionCircleDark } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

import { faClipboardList as faClipboardListLight } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faCabinetFiling as faCabinetFilingLight } from '@fortawesome/pro-regular-svg-icons/faCabinetFiling';
//import { faLightbulbExclamation as faExclamationBulbLight } from '@fortawesome/pro-regular-svg-icons/faLightbulbExclamation';
// import { faChartLine as faChartLineLight } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faCompressAlt as faCompressAltLight } from '@fortawesome/pro-regular-svg-icons/faCompressAlt';
import { faHandsHelping as faHandsHelpingLight } from '@fortawesome/pro-regular-svg-icons/faHandsHelping';
import { faDatabase as faDatabaseLight } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faFileAlt as faFileAltLight } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faGraduationCap as faTuitionLight } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faHome as faHomeLight } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faMoneyCheck as faMoneyCheckLight } from '@fortawesome/pro-regular-svg-icons/faMoneyCheck';
import { faPiggyBank as faPiggyBankLight } from '@fortawesome/pro-regular-svg-icons/faPiggyBank';
import { faSackDollar as faSackDollarLight } from '@fortawesome/pro-regular-svg-icons/faSackDollar';
import { faShieldAlt as faShieldAltLight } from '@fortawesome/pro-regular-svg-icons/faShieldAlt';
import { faUniversity as faUniversityLight } from '@fortawesome/pro-regular-svg-icons/faUniversity';
import { faUserShield as faUserShieldLight } from '@fortawesome/pro-regular-svg-icons/faUserShield';
import { faWheelchair as faWheelchairLight } from '@fortawesome/pro-regular-svg-icons/faWheelchair';
import { faMailbox as faMailboxLight } from '@fortawesome/pro-regular-svg-icons/faMailbox';
//import { faSearchDollar as faSearchDollarLight } from '@fortawesome/pro-regular-svg-icons/faSearchDollar';
//import { faHandHoldingUsd as faHandHoldingUsdLight } from '@fortawesome/pro-regular-svg-icons/faHandHoldingUsd';
import { faCheck as faCheckLight } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faExclamation as faExclamationLight } from '@fortawesome/pro-regular-svg-icons/faExclamation';
import { faLockAlt as faLockLight } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { faUserCircle as faUserCircleLight } from '@fortawesome/pro-regular-svg-icons/faUserCircle';

import { faClipboardList as faClipboardListDark } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faCabinetFiling as faCabinetFilingDark } from '@fortawesome/pro-solid-svg-icons/faCabinetFiling';
import { faCogs as faCogsDark } from '@fortawesome/pro-solid-svg-icons/faCogs';
import { faCogs as faCogsLight } from '@fortawesome/pro-regular-svg-icons/faCogs';
import { faCertificate as faCertificateDark } from '@fortawesome/pro-solid-svg-icons/faCertificate';
import { faCertificate as faCertificateLight } from '@fortawesome/pro-solid-svg-icons/faCertificate';
import { faBackward as faBackwardDark } from '@fortawesome/pro-solid-svg-icons/faBackward';
import { faBackward as faBackwardLight } from '@fortawesome/pro-regular-svg-icons/faBackward';
import { faBellSchool as faBellSchoolDark } from '@fortawesome/pro-solid-svg-icons/faBellSchool';
import { faBellSchool as faBellSchoolLight } from '@fortawesome/pro-regular-svg-icons/faBellSchool';
import { faMoneyBillWave as faMoneyBillWaveDark } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave';
import { faMoneyBillWave as faMoneyBillWaveLight } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWave';
import { faMoneyBill as faMoneyBillDark } from '@fortawesome/pro-solid-svg-icons/faMoneyBill';
import { faMoneyBill as faMoneyBillLight } from '@fortawesome/pro-regular-svg-icons/faMoneyBill';

// import { faChartLine as faChartLineDark } from '@fortawesome/pro-solid-svg-icons/faChartLine';
import { faCompressAlt as faCompressAltDark } from '@fortawesome/pro-solid-svg-icons/faCompressAlt';
import { faHandsHelping as faHandsHelpingDark } from '@fortawesome/pro-solid-svg-icons/faHandsHelping';
import { faDatabase as faDatabaseDark } from '@fortawesome/pro-solid-svg-icons/faDatabase';
import { faFileAlt as faFileAltDark } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faGraduationCap as faTuitionDark } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faHome as faHomeDark } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faMoneyCheck as faMoneyCheckDark } from '@fortawesome/pro-solid-svg-icons/faMoneyCheck';
import { faPiggyBank as faPiggyBankDark } from '@fortawesome/pro-solid-svg-icons/faPiggyBank';
import { faSackDollar as faSackDollarDark } from '@fortawesome/pro-solid-svg-icons/faSackDollar';
import { faShieldAlt as faShieldAltDark } from '@fortawesome/pro-solid-svg-icons/faShieldAlt';
import { faUniversity as faUniversityDark } from '@fortawesome/pro-solid-svg-icons/faUniversity';
import { faUserShield as faUserShieldDark } from '@fortawesome/pro-solid-svg-icons/faUserShield';
import { faWheelchair as faWheelchairDark } from '@fortawesome/pro-solid-svg-icons/faWheelchair';
import { faMailbox as faMailboxDark } from '@fortawesome/pro-solid-svg-icons/faMailbox';
//import { faSearchDollar as faSearchDollarDark } from '@fortawesome/pro-solid-svg-icons/faSearchDollar';
//import { faHandHoldingUsd as faHandHoldingUsdDark } from '@fortawesome/pro-solid-svg-icons/faHandHoldingUsd';
import { faCheck as faCheckDark } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamation as faExclamationDark } from '@fortawesome/pro-solid-svg-icons/faExclamation';
//import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faLockAlt as faLockDark } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faUserCircle as faUserCircleDark } from '@fortawesome/pro-solid-svg-icons/faUserCircle';

//import { faMoonStars } from '@fortawesome/pro-regular-svg-icons/faMoonStars';
//import { faSun } from '@fortawesome/pro-regular-svg-icons/faSun';

import {
  Badge,
  Dashboard,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownNavItem,
  Header5,
  HorizontalRule,
  Link,
  NavCategory,
  NavItem,
  NavList,
  NavSide,
  NavTop,
} from 'fiducius-ui';

import { MyMeetings } from '../../meetings';
import { withPermissions } from '../../routing';
import { MyTodos } from '../../todos';
import { safeAccess, getApiSingle, patchApi, normalize } from '../../utils';
import { getCRMUserId, getUserId } from '../../auth';

import RouterDashboard from './router-dashboard';
import ActionIcon from '../styles/action-icon';
import DrawerItems from '../styles/drawer-items';
import PaddedBadge from '../styles/padded-badge';
import { getYear } from 'date-fns';

class LayoutDashboard extends React.Component {
  static propTypes = {
    changeFluid: t.func.isRequired,
    changeTheme: t.func.isRequired,
    darkTheme: t.bool.isRequired,
    drawers: t.object.isRequired,
    fluid: t.bool.isRequired,
    permissions: t.object,
    history: t.shape({
      push: t.func.isRequired,
    }),
    toggleDrawer: t.func.isRequired,
    getCRMUserId: t.func.isRequired,
    getUserId: t.func.isRequired,
    theme: t.string.isRequired,
    toDos: t.object.isRequired,
    navigationOpen: t.bool.isRequired,
    toggleNavigation: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEmulation: false,
      emulationId: 0,
      darkThemeState: false,
    };
  }

  componentWillMount() {
    const { getUserId, changeTheme } = this.props;
    const Id = getUserId();
    getApiSingle(null, '/settings/' + Id).then((data) => {
      changeTheme(data.theme);
    });
  }

  componentDidMount() {
    const { getCRMUserId, getUserId, permissions } = this.props;
    const crmId = getCRMUserId();

    if (crmId !== 'Error') {
      const userId = parseInt(getUserId()) + 1000;
      this.setState({ ...this.state, isEmulation: true, emulationId: userId });
    }

    if (permissions.hasBenefitTools) {
      var sc = document.createElement('script');
      sc.setAttribute('id', 'ze-snippet');
      sc.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=29d416e9-9be3-4755-aac3-95c3faaa931f'
      );
      sc.setAttribute('type', 'text/javascript');
      sc.onload = function() {
        window.resetChat();
        window.showChat();
      };
      document.head.appendChild(sc);
    }
  }

  updateThemeSettings(value) {
    const { getUserId, changeTheme } = this.props;
    const Id = getUserId();

    const patchData = normalize(
      'settings',
      {
        theme: value,
      },
      Id
    );
    changeTheme(value);
    patchApi(null, '/settings/' + Id, patchData);
  }

  render() {
    const {
      permissions,
      changeTheme,
      darkTheme,
      drawers,
      history,
      toggleDrawer,
      theme,
      toDos,
      navigationOpen,
      toggleNavigation,
    } = this.props;
    const { isEmulation, emulationId } = this.state;
    let emulationMessage = '';
    if (isEmulation) {
      emulationMessage = 'You are currently emulating account X' + emulationId.toString() + '.';
    }

    //this.updateThemeSettings('dark')

    return (
      <>
        <Dashboard
          sidebar={
            <SideBar theme={theme} darkTheme={darkTheme} permissions={permissions} toDos={toDos} />
          }
          header={
            <TopBar
              toggleDrawer={toggleDrawer}
              history={history}
              permissions={permissions}
              emulationMessage={emulationMessage}
              changeTheme={changeTheme}
              state={(e) => this.updateThemeSettings(e)}
              theme={theme}
              sidebar={
                <SideBar
                  theme={theme}
                  darkTheme={darkTheme}
                  permissions={permissions}
                  toDos={toDos}
                  onClick={toggleNavigation}
                />
              }
              toggleNavigation={toggleNavigation}
              navigationOpen={navigationOpen}
            />
          }
        >
          <RouterDashboard toggleDrawer={toggleDrawer} theme={theme} isEmulation={isEmulation} />
        </Dashboard>
        <Drawer isOpen={safeAccess(drawers, 'meetings', false)}>
          <DrawerItems>
            <MyMeetings />
          </DrawerItems>
        </Drawer>
        <Drawer isOpen={safeAccess(drawers, 'todos', false)}>
          <DrawerItems>
            <MyTodos toggleDrawer={toggleDrawer} toggleNavigation={toggleNavigation} />
          </DrawerItems>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCRMUserId: () => getCRMUserId(state),
    getUserId: () => getUserId(state),
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LayoutDashboard));

const DropdownAdjust = styled(Dropdown)`
  margin-right: 0.5em;
  color: ${(p) =>
    p.theme.themeName === 'greenlight' ? p.theme.textPrimary : p.theme.textTertiary};
`;

const StyledDrawerButton = styled.div`
  position: relative;
`;

const OffsetBadge = styled(Badge)`
  font-size: 0.85em;
  position: absolute;
  left: 75%;
  top: -0.5rem;
`;

const DrawerButton = ({ propKey, icon, size, onClick, badge = null }) => (
  <StyledDrawerButton>
    <ActionIcon key={propKey} icon={icon} fixedWidth size={size} onClick={onClick} />
    {badge != null && badge !== 0 && <OffsetBadge brand="primary">{badge}</OffsetBadge>}
  </StyledDrawerButton>
);

DrawerButton.propTypes = {
  propKey: t.string,
  icon: t.any,
  size: t.string,
  onClick: t.func,
  badge: t.node,
};

const mapTodosStateToIcon = (state) => ({
  todosCount: getActionItemLength(state.todos.cache),
});

const getActionItemLength = (todos) => {
  let todoArray = Object.values(todos);
  if (Object.values(todos).length > 0) {
    let ecfActionIdArray = [106, 107, 108, 252, 253, 254];
    let lwoEcfActionIdArray = [606, 607, 608];

    //check to see if ECF action items overlap - we only want LWO action items.
    if (
      todoArray.filter((a) => ecfActionIdArray.includes(a.id)).length > 0 &&
      todoArray.filter((a) => lwoEcfActionIdArray.includes(a.id)).length > 0
    ) {
      let index;
      ecfActionIdArray.forEach((a) => {
        let toDoIds = todoArray.map((b) => b.id);
        index = toDoIds.indexOf(a);
        if (index >= 0) {
          todoArray.splice(index, 1);
        }
      });
    }
  }
  return todoArray.length;
};

const getCurrentYear = () => {
  return getYear(new Date());
};

const getAvailableActions = (
  toggleDrawer,
  todosCount,
  permissions,
  updateThemeSettings,
  toggleNavigation
) => {
  let profileDropdown = (
    <DropdownAdjust
      key="profile"
      header={<ActionIcon fixedWidth size="2x" icon={faUserCircle} />}
      unstyled={true}
      align="right"
    >
      <DropdownNavItem to="/profile">My Profile</DropdownNavItem>
      <HorizontalRule />
      <DropdownNavItem to="/update-password-auth">Update Password</DropdownNavItem>
      <HorizontalRule />
      <DropdownNavItem to="/my-security-questions">Security Questions</DropdownNavItem>
      <HorizontalRule />
      <DropdownNavItem to="/logout">Logout</DropdownNavItem>
    </DropdownAdjust>
  );

  if (permissions.useMFA > 0) {
    profileDropdown = (
      <DropdownAdjust
        key="profile"
        header={<ActionIcon fixedWidth size="2x" icon={faUserCircle} />}
        unstyled={true}
        align="right"
      >
        <DropdownNavItem to="/profile">My Profile</DropdownNavItem>
        <HorizontalRule />
        <DropdownNavItem to="/update-password-auth">Update Password</DropdownNavItem>
        <HorizontalRule />
        <DropdownNavItem to="/my-security-questions">Security Questions</DropdownNavItem>
        <HorizontalRule />
        <DropdownNavItem to="/logout">Logout</DropdownNavItem>
      </DropdownAdjust>
    );
  }

  let actions = [
    <Dropdown
      key="exampleTheme"
      header="Theme"
      //selected={state.theme}
      onSelect={updateThemeSettings} //make a new function that gets change theme func, and async
      align="left"
    >
      <DropdownItem value="newlight">Light - New</DropdownItem>
      <DropdownItem value="dark">Dark</DropdownItem>
      <DropdownItem value="greenlight">Green</DropdownItem>
    </Dropdown>,
    <DrawerButton
      key="todos"
      propKey="todos"
      icon={faTasks}
      fixedWidth
      size="2x"
      onClick={() => toggleDrawer('todos')}
      badge={todosCount}
    />,
    <DrawerButton
      key="meetings"
      propKey="meetings"
      icon={faCalendarAlt}
      fixedWidth
      size="2x"
      onClick={() => toggleDrawer('meetings')}
    />,
    profileDropdown,
  ];

  if (permissions.clientStatus === 'CLOSE' || permissions.clientStatus === 'OHPAY') {
    actions = [
      <DropdownAdjust
        key="profile"
        header={<FontAwesomeIcon fixedWidth size="2x" icon={faUserCircle} />}
        unstyled={true}
        align="right"
      >
        <DropdownNavItem to="/logout">Logout</DropdownNavItem>
      </DropdownAdjust>,
    ];
  }

  return actions;
};

const TopBar = connect(mapTodosStateToIcon)(
  withTheme(
    ({
      history,
      todosCount = 0,
      toggleDrawer,
      permissions,
      emulationMessage,
      changeTheme,
      darkTheme,
      changeFluid,
      fluid,
      state,
      sidebar,
      navigationOpen,
      toggleNavigation,
      theme,
    }) => (
      <NavTop
        navigationOpen={navigationOpen}
        toggleNavigation={toggleNavigation}
        sidebar={sidebar}
        theme={theme}
        links={[
          <Header5 key="about">
            <Link to="https://getfiducius.com" external>
              About Us
            </Link>
          </Header5>,
          <Header5 key="success-stories">
            <Link to="https://getfiducius.com/success-stories" external>
              Success Stories
            </Link>
          </Header5>,
          <Header5 key="emulation">{emulationMessage}</Header5>,
        ]}
        actions={getAvailableActions(
          toggleDrawer,
          todosCount,
          permissions,
          changeTheme,
          darkTheme,
          state
        )}
      />
    )
  )
);

TopBar.propTypes = {
  history: t.shape({
    push: t.func.isRequired,
  }),
  toggleDrawer: t.func.isRequired,
  sidebar: t.node.isRequired,
  navigationOpen: t.bool,
  toggleNavigation: t.func.isRequired,
  theme: t.string.isRequired,
};

const Copyright = styled.div`
  align-items: stretch;
  color: ${(p) => p.theme.textTertiary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;

  & > small {
    padding: 0 2rem;
    padding-top: 0.5rem;
  }
`;

const SideBarHome = (darkTheme, permissions, toDos, onClick) => {
  return (
    <>
      {permissions.clientStatus !== 'CLOSE' && permissions.clientStatus !== 'OHPAY' && (
        <>
          <NavCategory>Home</NavCategory>
          <NavItem path="/dashboard" icon={darkTheme ? faHomeDark : faHomeLight} onClick={onClick}>
            My Dashboard
          </NavItem>
          <NavItem
            path="/profile"
            icon={darkTheme ? faUserCircleDark : faUserCircleLight}
            onClick={onClick}
          >
            My Profile
            {(Object.keys(toDos).includes('1601') ||
              Object.keys(toDos).includes('421') ||
              Object.keys(toDos).includes('209') ||
              Object.keys(toDos).includes('10')) && (
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            )}
          </NavItem>
          <NavItem
            path="/accounts"
            icon={darkTheme ? faUniversityDark : faUniversityLight}
            onClick={onClick}
          >
            My Accounts
            {(Object.keys(toDos).includes('502') ||
              Object.keys(toDos).includes('501') ||
              Object.keys(toDos).includes('402') ||
              Object.keys(toDos).includes('403') ||
              Object.keys(toDos).includes('13') ||
              Object.keys(toDos).includes('11')) && (
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            )}
          </NavItem>
          <NavItem
            path="/documents"
            icon={darkTheme ? faCabinetFilingDark : faCabinetFilingLight}
            onClick={onClick}
          >
            My Documents
            {(Object.keys(toDos).includes('2') ||
              Object.keys(toDos).includes('3') ||
              Object.keys(toDos).includes('4') ||
              Object.keys(toDos).includes('411') ||
              Object.keys(toDos).includes('412') ||
              Object.keys(toDos).includes('413')) && (
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            )}
          </NavItem>

          {/* <NavItem to="/future" icon={darkTheme ? faChartLineDark : faChartLineLight}>
    My Future
  </NavItem> */}
        </>
      )}
    </>
  );
};

const SidebarText = styled.span`
  padding: 0px;
`;

const getLwoSteps = (lwoSteps, onClick, darkTheme) => {
  let retLwoSteps = [];
  lwoSteps.forEach((a) => {
    if (a.id === 'Overview' || a.id === 'About') {
      retLwoSteps.push(
        <NavItem
          path={'/benefits/limited-waiver-opportunity' + a.stepPath}
          subItem
          onClick={onClick}
        >
          <SidebarText className="col-12">{a.stepText}</SidebarText>
        </NavItem>
      );
    } else if (a.stepComplete) {
      retLwoSteps.push(
        <NavItem
          path={'/benefits/limited-waiver-opportunity' + a.stepPath}
          subItem
          onClick={onClick}
        >
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="success">
              <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    } else if (a.stepCurrent) {
      retLwoSteps.push(
        <NavItem
          path={'/benefits/limited-waiver-opportunity' + a.stepPath}
          subItem
          onClick={onClick}
        >
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="primary">
              <FontAwesomeIcon icon={faExclamationDark} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    } else {
      retLwoSteps.push(
        <NavItem
          path={'/benefits/limited-waiver-opportunity' + a.stepPath}
          subItem
          onClick={onClick}
        >
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="warning">
              <FontAwesomeIcon icon={darkTheme ? faLockDark : faLockLight} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    }
  });
  return retLwoSteps;
};

const getEnterpriseEmpcertSteps = (enterpriseEmpcertSteps, onClick, darkTheme) => {
  let retEnterpriseEmpcertSteps = [];
  enterpriseEmpcertSteps.forEach((a) => {
    if (a.stepComplete) {
      retEnterpriseEmpcertSteps.push(
        <NavItem path={'/benefits/empcert' + a.stepPath} subItem onClick={onClick}>
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="success">
              <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    } else if (a.stepCurrent) {
      retEnterpriseEmpcertSteps.push(
        <NavItem path={'/benefits/empcert' + a.stepPath} subItem onClick={onClick}>
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="primary">
              <FontAwesomeIcon icon={faExclamationDark} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    } else {
      retEnterpriseEmpcertSteps.push(
        <NavItem path={'/benefits/empcert' + a.stepPath} subItem onClick={onClick}>
          <SidebarText className="col-12">
            {a.stepText} &nbsp;
            <PaddedBadge brand="warning">
              <FontAwesomeIcon icon={darkTheme ? faLockDark : faLockLight} />
            </PaddedBadge>
          </SidebarText>
        </NavItem>
      );
    }
  });
  return retEnterpriseEmpcertSteps;
};

const getFreshStartSteps = (freshStartSteps, onClick, darkTheme) => {
  let retFreshStartSteps = [];
  freshStartSteps.forEach((a) => {
    if (a.id === 'OverviewFreshStart' || a.id === 'About') {
      retFreshStartSteps.push(
        <NavItem path={'/benefits/freshstart' + a.stepPath} subItem onClick={onClick}>
          <SidebarText className="col-12">{a.stepText}</SidebarText>
        </NavItem>
      );
    } else {
      if (a.stepComplete) {
        retFreshStartSteps.push(
          <NavItem path={'/benefits/freshstart' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="success">
                <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else if (a.stepCurrent) {
        retFreshStartSteps.push(
          <NavItem path={'/benefits/freshstart' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else {
        retFreshStartSteps.push(
          <NavItem path={'/benefits/freshstart' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="warning">
                <FontAwesomeIcon icon={darkTheme ? faLockDark : faLockLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      }
    }
  });
  return retFreshStartSteps;
};

const getReducedIDRSteps = (reducedIDRSteps, onClick, darkTheme) => {
  let retReducedIDRSteps = [];
  reducedIDRSteps.forEach((a) => {
    if (a.id === 'OverviewReducedIDR' || a.id === 'About') {
      retReducedIDRSteps.push(
        <NavItem path={'/benefits/reducedidr' + a.stepPath} subItem onClick={onClick}>
          <SidebarText className="col-12">{a.stepText}</SidebarText>
        </NavItem>
      );
    } else {
      if (a.stepComplete) {
        retReducedIDRSteps.push(
          <NavItem path={'/benefits/reducedidr' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="success">
                <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else if (a.stepCurrent) {
        retReducedIDRSteps.push(
          <NavItem path={'/benefits/reducedidr' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else {
        retReducedIDRSteps.push(
          <NavItem path={'/benefits/reducedidr' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="warning">
                <FontAwesomeIcon icon={darkTheme ? faLockDark : faLockLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      }
    }
  });
  return retReducedIDRSteps;
};

const SideBarBenefits = (darkTheme, permissions, toDos, onClick) => {
  let forgivenessSteps = [];
  if (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) {
    permissions.forgivenessSteps.forEach((a) => {
      if (!a.stepComplete) {
        if (a.stepCurrent) {
          forgivenessSteps.push(
            <NavItem path={'/benefits/forgiveness' + a.stepPath} subItem onClick={onClick}>
              <SidebarText className="col-12">
                {a.stepText} &nbsp;
                <PaddedBadge brand="primary">
                  <FontAwesomeIcon icon={faExclamationDark} />
                </PaddedBadge>
              </SidebarText>
            </NavItem>
          );
        } else if (a.id === 'Overview') {
          forgivenessSteps.push(
            <NavItem path={'/benefits/forgiveness' + a.stepPath} subItem onClick={onClick}>
              <SidebarText className="col-12">{a.stepText}</SidebarText>
            </NavItem>
          );
        } else {
          forgivenessSteps.push(
            <NavItem path={'/benefits/forgiveness' + a.stepPath} subItem onClick={onClick}>
              <SidebarText className="col-12">
                {a.stepText} &nbsp;
                <PaddedBadge brand="warning">
                  <FontAwesomeIcon icon={darkTheme ? faLockDark : faLockLight} />
                </PaddedBadge>
              </SidebarText>
            </NavItem>
          );
        }
      } else {
        if (a.id === 'BtsReview') {
          forgivenessSteps.push(
            <NavItem path={'/benefits/forgiveness' + a.stepPath} subItem onClick={onClick}>
              <SidebarText className="col-12">{a.stepText}</SidebarText>
            </NavItem>
          );
        } else {
          forgivenessSteps.push(
            <NavItem path={'/benefits/forgiveness' + a.stepPath} subItem onClick={onClick}>
              <SidebarText className="col-12">
                {a.stepText} &nbsp;
                <PaddedBadge brand="success">
                  <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
                </PaddedBadge>
              </SidebarText>
            </NavItem>
          );
        }
      }
    });
  }
  return (
    <>
      {permissions.hasAnyBenefits && (
        <>
          <NavCategory>My Benefits</NavCategory>
          {permissions.showForgiveness && (
            <NavList
              title="Forgiveness"
              icon={darkTheme ? faHandsHelpingDark : faHandsHelpingLight}
              path="/benefits/forgiveness"
            >
              {forgivenessSteps}
            </NavList>
          )}
          {permissions.hasEnterpriseEmpCert &&
            permissions.enterpriseEmpcertSteps !== undefined &&
            permissions.enterpriseEmpcertSteps !== null && (
              <NavList
                title={<>Emplify</>}
                icon={darkTheme ? faCertificateDark : faCertificateLight}
                path="/benefits/empcert"
              >
                {getEnterpriseEmpcertSteps(permissions.enterpriseEmpcertSteps, onClick, darkTheme)}
              </NavList>
            )}
          {permissions.hasFreshStart &&
            permissions.freshStartSteps !== undefined &&
            permissions.freshStartSteps !== null && (
              <NavList
                title={<>Fresh Start</>}
                icon={darkTheme ? faBackwardDark : faBackwardLight}
                path="/benefits/freshstart"
              >
                {getFreshStartSteps(permissions.freshStartSteps, onClick, darkTheme)}
              </NavList>
            )}
          {permissions.hasReducedIDR &&
            permissions.reducedIdrSteps !== undefined &&
            permissions.reducedIdrSteps !== null && (
              <NavList
                title={<>Reduced IDR</>}
                icon={darkTheme ? faMoneyBillWaveDark : faMoneyBillWaveLight}
                path="/benefits/reducedidr"
              >
                {getReducedIDRSteps(permissions.reducedIdrSteps, onClick, darkTheme)}
              </NavList>
            )}
          {permissions.hasEducationalReengagement && (
            <NavList
              title={<>Educational Reengagement</>}
              icon={darkTheme ? faBellSchoolDark : faBellSchoolLight}
              path="/benefits/educational-reengagement"
            >
              <NavItem path="/benefits/educational-reengagement/overview" subItem onClick={onClick}>
                Overview
                {Object.keys(toDos).includes('1000') && (
                  <PaddedBadge brand="primary">
                    <FontAwesomeIcon icon={faExclamationDark} />
                  </PaddedBadge>
                )}
              </NavItem>
            </NavList>
          )}
          {permissions.showLwo && (
            <NavList
              title={<>Assure&#8480;</>}
              icon={darkTheme ? faCogsDark : faCogsLight}
              path="/benefits/limited-waiver-opportunity"
            >
              {permissions.lwoSteps !== undefined &&
                permissions.lwoSteps !== null &&
                getLwoSteps(permissions.lwoSteps, onClick, darkTheme)}
            </NavList>
          )}
          {(permissions.showContribution || permissions.contributionReadOnly) && (
            <NavList
              title="Contribution"
              icon={darkTheme ? faPiggyBankDark : faPiggyBankLight}
              path="/benefits/contribution"
            >
              {!permissions.contributionReadOnly && (
                <>
                  <NavItem path="/benefits/contribution/overview" subItem onClick={onClick}>
                    Overview
                    {/*
                -- Might need this later
                {Object.keys(toDos).includes('15') && (
                  <PaddedBadge brand="primary">
                    <FontAwesomeIcon icon={faExclamationDark} />
                  </PaddedBadge>
                )} */}
                  </NavItem>
                  <NavItem
                    path="/benefits/contribution/active-contributions"
                    subItem
                    onClick={onClick}
                  >
                    Active Contributions
                    {Object.keys(toDos).includes('504') && (
                      <PaddedBadge brand="primary">
                        <FontAwesomeIcon icon={faExclamationDark} />
                      </PaddedBadge>
                    )}
                  </NavItem>

                  <NavItem
                    path="/benefits/contribution/inactive-contributions"
                    subItem
                    onClick={onClick}
                  >
                    Inactive Contributions
                  </NavItem>
                </>
              )}
            </NavList>
          )}
          {(permissions.showTuition || permissions.tuitionReadOnly) && (
            <NavList
              title="Tuition Reimbursement"
              icon={darkTheme ? faTuitionDark : faTuitionLight}
              path="/benefits/tuition"
            >
              {!permissions.tuitionReadOnly && (
                <>
                  <NavItem path="/benefits/tuition/overview" subItem onClick={onClick}>
                    Overview
                    {Object.keys(toDos).includes('401') && (
                      <PaddedBadge brand="primary">
                        <FontAwesomeIcon icon={faExclamationDark} />
                      </PaddedBadge>
                    )}
                  </NavItem>
                  {!permissions.homeAddressIsMissing && (
                    <>
                      <NavItem path="/benefits/tuition/institutions" subItem onClick={onClick}>
                        Institutions
                        {Object.keys(toDos).includes('422') && (
                          <PaddedBadge brand="primary">
                            <FontAwesomeIcon icon={faExclamationDark} />
                          </PaddedBadge>
                        )}
                      </NavItem>

                      <NavItem path="/benefits/tuition/terms" subItem onClick={onClick}>
                        Terms
                        {(Object.keys(toDos).includes('423') ||
                          Object.keys(toDos).includes('424')) && (
                          <PaddedBadge brand="primary">
                            <FontAwesomeIcon icon={faExclamationDark} />
                          </PaddedBadge>
                        )}
                      </NavItem>

                      <NavItem path="/benefits/tuition/classes" subItem onClick={onClick}>
                        Classes
                        {(Object.keys(toDos).includes('425') ||
                          Object.keys(toDos).includes('426')) && (
                          <PaddedBadge brand="primary">
                            <FontAwesomeIcon icon={faExclamationDark} />
                          </PaddedBadge>
                        )}
                      </NavItem>
                    </>
                  )}
                </>
              )}
              <NavItem path="/benefits/tuition/history" subItem onClick={onClick}>
                History
              </NavItem>
            </NavList>
          )}
          {permissions.showSimplifi && (
            <NavItem
              path="/benefits/simplifi"
              icon={darkTheme ? faCompressAltDark : faCompressAltLight}
              onClick={onClick}
            >
              SimpliFi
              {Object.keys(toDos).includes('301') && (
                <PaddedBadge brand="primary">
                  <FontAwesomeIcon icon={darkTheme ? faExclamationDark : faExclamationLight} />
                </PaddedBadge>
              )}
            </NavItem>
          )}
          {permissions.showRefinance && (
            <NavItem
              path="/benefits/refinance"
              icon={darkTheme ? faMoneyCheckDark : faMoneyCheckLight}
              onClick={onClick}
            >
              Refinance
            </NavItem>
          )}
          {permissions.showRetirement && (
            <NavItem
              path="/benefits/retirement"
              icon={darkTheme ? faSackDollarDark : faSackDollarLight}
              onClick={onClick}
            >
              Retirement
            </NavItem>
          )}

          {permissions.showIdentity && (
            <NavItem
              path="/benefits/identity"
              icon={darkTheme ? faUserShieldDark : faUserShieldLight}
              onClick={onClick}
            >
              Identity Protection
            </NavItem>
          )}
          {permissions.showStudentLoanDisabilityInsurance && (
            <NavItem
              path="/benefits/insurance"
              icon={darkTheme ? faWheelchairDark : faWheelchairLight}
              onClick={onClick}
            >
              Disability Insurance
            </NavItem>
          )}
        </>
      )}
    </>
  );
};

const SideBarLoanRepayments = (darkTheme, permissions, toDos, onClick) => {
  // Begin Submenu code
  // Shows the name of the step, and a green check if complete, or an orange exclamation if not completed
  // Currently shows 'Overview' under the main left nav menu
  let loanRepaymentSteps = [];
  if (permissions.loanRepaymentSteps !== undefined && permissions.loanRepaymentSteps !== null) {
    permissions.loanRepaymentSteps.forEach((a) => {
      if (a.stepCurrent) {
        loanRepaymentSteps.push(
          <NavItem
            icon={darkTheme ? faMoneyBillDark : faMoneyBillLight}
            path={'/loan-repayments' + a.stepPath}
            onClick={onClick}
          >
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else {
        loanRepaymentSteps.push(
          <NavItem
            icon={darkTheme ? faMoneyBillDark : faMoneyBillLight}
            path={'/loan-repayments' + a.stepPath}
            onClick={onClick}
          >
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="success">
                <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      }
    });
    //End submenu code
  }
  return (
    <>
      {permissions.hasLoanRepayment && (
        <>
          <NavCategory>Michigan 27k</NavCategory>
          {permissions.hasLoanRepayment && <>{loanRepaymentSteps}</>}
        </>
      )}
    </>
  );
};

const SideBarTools = (darkTheme, permissions, onClick) => {
  let plaidSteps = [];
  if (permissions.plaidSteps !== undefined && permissions.plaidSteps !== null) {
    permissions.plaidSteps.forEach((a) => {
      if (a.stepCurrent) {
        plaidSteps.push(
          <NavItem path={'/plaid' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="primary">
                <FontAwesomeIcon icon={faExclamationDark} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      } else {
        plaidSteps.push(
          <NavItem path={'/plaid' + a.stepPath} subItem onClick={onClick}>
            <SidebarText className="col-12">
              {a.stepText} &nbsp;
              <PaddedBadge brand="success">
                <FontAwesomeIcon icon={darkTheme ? faCheckDark : faCheckLight} />
              </PaddedBadge>
            </SidebarText>
          </NavItem>
        );
      }
    });
  }

  return (
    <>
      {(permissions.showContribution ||
        permissions.showPolaris ||
        permissions.hasPlaid ||
        permissions.hasBenefitTools) && (
        <>
          <NavCategory>Benefit Tools</NavCategory>
          {permissions.hasPlaid && (
            <NavList
              title="Plaid"
              icon={darkTheme ? faMoneyCheckDark : faMoneyCheckLight}
              path="/plaid"
            >
              {plaidSteps}
            </NavList>
          )}
          <NavList
            title="Benefit Tools"
            icon={darkTheme ? faClipboardListDark : faClipboardListLight}
            path="/tools"
          >
            {permissions.showPolaris && (
              <NavItem path="/tools/plan-tool" subItem onClick={onClick}>
                Polaris
              </NavItem>
            )}
            {permissions.hasBenefitTools && (
              <>
                {/*<NavItem to="/tools/modeler" >
                Retirement Tool
          </NavItem>*/}

                <NavItem path="/tools/retirement-tool" subItem onClick={onClick}>
                  Retirement Comparison
                </NavItem>

                <NavItem path="/tools/pretax-tool" subItem onClick={onClick}>
                  Pre-Tax Tool
                </NavItem>

                <NavItem path="/tools/tax-calculator" subItem onClick={onClick}>
                  PaySmart Loan Navigator
                </NavItem>
                {/* <NavItem path="/tools/taxtool" subItem onClick={onClick}>
                  Tax Estimator Tool
                </NavItem> */}

                <NavItem path="/tools/interestcomparison" subItem onClick={onClick}>
                  Interest Comparison
                </NavItem>
              </>
            )}
            {permissions.showContribution && (
              <NavItem path="/tools/sla-comparison" subItem onClick={onClick}>
                Contribution Comparison
              </NavItem>
            )}
          </NavList>
        </>
      )}
    </>
  );
};

const SideBar = withPermissions(({ darkTheme, permissions, theme, toDos, onClick }) => {
  let clientStatus = permissions && permissions.clientStatus;
  return (
    <NavSide theme={theme}>
      {clientStatus !== 'CLOSE' &&
        clientStatus !== 'OHPAY' &&
        SideBarHome(darkTheme, permissions, toDos, onClick)}
      {clientStatus !== 'CLOSE' &&
        clientStatus !== 'OHPAY' &&
        SideBarBenefits(darkTheme, permissions, toDos, onClick)}
      {clientStatus !== 'CLOSE' &&
        clientStatus !== 'OHPAY' &&
        SideBarLoanRepayments(darkTheme, permissions, toDos, onClick)}
      {clientStatus !== 'CLOSE' &&
        clientStatus !== 'OHPAY' &&
        SideBarTools(darkTheme, permissions, onClick)}
      <NavCategory>Powered by Fiducius</NavCategory>
      <NavItem
        path="/privacy"
        icon={darkTheme ? faShieldAltDark : faShieldAltLight}
        onClick={onClick}
      >
        Privacy
      </NavItem>
      <NavItem
        path="/terms-of-use"
        icon={darkTheme ? faFileAltDark : faFileAltLight}
        onClick={onClick}
      >
        Terms of Use
      </NavItem>
      {permissions.clientStatus !== 'CLOSE' &&
        permissions.clientStatus !== 'OHPAY' &&
        permissions.showSharedData && (
          <NavItem
            path="/shared-data"
            icon={darkTheme ? faDatabaseDark : faDatabaseLight}
            onClick={onClick}
          >
            Shared Data Consent
          </NavItem>
        )}
      <NavItem
        path="/faq"
        icon={darkTheme ? faQuestionCircleDark : faQuestionCircleLight}
        onClick={onClick}
      >
        Frequently Asked Questions
      </NavItem>
      {(permissions.enrolledForgiveness ||
        permissions.clientStatus === 'WAIT' ||
        permissions.clientStatus === 'LEAD' ||
        permissions.clientStatus === 'PLAN' ||
        permissions.clientStatus === 'FUPHB') && (
        <NavItem
          path="/contact"
          icon={darkTheme ? faMailboxDark : faMailboxLight}
          onClick={onClick}
        >
          Contact Us
        </NavItem>
      )}
      <Copyright>
        <small>&copy; {getCurrentYear()} Fiducius, LLC.</small>
        <small>All rights reserved.</small>
      </Copyright>
    </NavSide>
  );
});

SideBar.propTypes = {
  darkTheme: t.bool.isRequired,
  theme: t.string.isRequired,
  toDos: t.object.isRequired,
  permissons: t.object.isRequired,
  onClick: t.func,
};
